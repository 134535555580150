import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"


const Studio = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]}/>
  </Layout>
);

export default Studio